$black: #000000;
$blue: #0000ff;
$white:#ffffff;
$std_font_size: 16px;

/* width */
::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 0px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #46434361; 
    border-radius: 0px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: black; 
  }