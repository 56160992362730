// Global App SCSS

@import './variables.scss';
//@import "./rbc/rbc.scss";
@import './sidebar.scss';
@import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../../node_modules/bootstrap/dist/css/bootstrap-grid.min.css';
@import '../../node_modules/bootstrap/dist/css/bootstrap-reboot.min.css';
//@import "bootstrap-extended/bootstrap";

body {
  margin: auto 0;
  height: 100vh;
  overflow-y: scroll;
  font-family: Gordita, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif;
  font-size: $std_font_size !important;
  background: rgb(230, 224, 224);
  background: linear-gradient(
    180deg,
    rgba(230, 224, 224, 1) 4%,
    rgba(255, 255, 255, 1) 100%
  );
}
a {
  cursor: pointer !important;
}
.brand-image {
  width: 110px;
  margin-right: 10px;
}
hr {
  border-top: 1px dashed grey !important;
}
.f-16 {
  font-size: $std_font_size !important;
}
.f-15 {
  font-size: 15px;
}
.p-50 {
  padding: 4rem !important;
}
.form-center {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 6px;
  box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.1);
  margin: 0 auto 30px;
  padding: 30px;
  // position: absolute;
  top: 5%;
  left: 30%;
  text-align: left;
  vertical-align: top;
}
.headline {
  margin-bottom: 30px;
  .tagline {
    margin: 15px 0 0;
    font-size: 20px;
  }
  h1 {
    font-size: 24px;
    font-weight: 600;
  }
}
.center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.nav-brand {
  font-size: 30px !important;
  color: rgb(42, 42, 211) !important;
}
.dropdown-menu {
  width: 190px !important;
  height: 220px !important;
}
.nav-item a {
  color: $black;
  font-size: 20px;
}
label {
  float: left;
}
.img-circle {
  border: 1px solid #abb5d0;
  border-radius: 50%;
  height: 70px;
  width: 70px;
}
.btn:disabled {
  cursor: not-allowed;
}
.home-container {
  max-width: 100% !important;
  padding: 0 !important;
  // margin-top: 100px;
  .navbar-text {
    color: white;
    text-decoration: underline;
  }
}
.table thead tr,
.table tbody tr {
  font-size: 14px;
}
h2 {
  font-size: 25px !important;
}
h3 {
  font-size: 22px !important;
}
h4 {
  font-size: 20px !important;
}
.btn {
  font-size: $std_font_size !important;
}
.nav-item a {
  font-size: $std_font_size;
}
input,
textarea,
select {
  font-size: $std_font_size !important;
}
.nav-tabs .nav-item .nav-link {
  font-size: 17px;
}
.table thead tr th,
.table tbody tr td,
.table tbody tr th {
  padding: 2rem;
}
.page-heading {
  padding: 15px 0px;
  font-weight: bold;
  background: #5a5d66;
  color: #eebb6b;
  font-size: 25px !important;
}
.loading {
  background-color: #7985b1;
  color: white;
}
.action {
  color: blue;
  cursor: pointer;
  &:hover {
    color: black;
    cursor: pointer;
    text-decoration: underline;
  }
}
.rfloat {
  float: right !important;
}
.lfloat {
  float: left !important;
}
.no-wrap {
  white-space: nowrap;
}
.hide {
  display: none;
}
.w-100 {
  width: 100%;
}
.table {
  background-color: #f3f3f3;
}
#feedback-table tr:nth-of-type(odd) {
  background-color: cyan;
}
#feedback-table thead tr:first-child {
  background-color: #dedae6;
}
#feedback-table tr:nth-of-type(even) {
  background-color: #f7f7f6;
}
.table tbody tr td {
  padding: 10px;
  text-align: center;
  vertical-align: middle;
}
.table tbody tr th {
  padding: 10px;
  text-align: center;
  vertical-align: middle;
}
.table thead tr th {
  padding: 15px;
  text-align: center;
  vertical-align: middle;
}
#timesheet-table tr:last-child {
  background-color: transparent;
}
.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.word-break {
  word-break: break-word;
}
#timesheet-table tr td:last-child {
  display: inline-flex;
}
.add-button,
.delete-button {
  margin-top: 15px !important;
}
label {
  font-size: 15px !important;
}
.page-link {
  font-size: 15px !important;
}
#timesheet-table tr:last-child td .add-button {
  display: block !important;
}
#timesheet-table tr:only-child td .delete-button {
  display: none !important;
}
#add-user-table tr:last-child {
  background-color: transparent;
}
#add-user-table tr td:last-child {
  display: inline-flex;
}
#add-user-table tr:last-child td .add-button {
  display: block !important;
}
#add-user-table tr:only-child td .delete-button {
  display: none !important;
}
#teammember-table tr td:last-child {
  display: inline-flex;
}
#teammember-table tr:last-child td .add-button {
  display: block !important;
}
#teammember-table {
  table-layout: fixed !important;
}
#teammember-table tr td {
  padding: 5px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
#welcome-page-table tr td {
  padding: 10px;
}
#welcome-page-table tr th {
  padding: 10px;
}
.btn-link.disabled,
.btn-link:disabled {
  pointer-events: all;
  cursor: not-allowed !important;
}
.user-image {
  width: 40px;
  height: 40px;
  background-image: url('../static/images/user-icon.png');
  background-color: burlywood;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
}
input[type='date']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}
.leave-headings {
  font-size: 30px;
  text-align: center !important;
  padding-top: 20px;
}
.to-end-start {
  justify-content: flex-end !important;
}

.dashboard-tiles {
  background-color: #fff;
  border: 1px solid #f9f0f0;
  color: black !important;
  padding: 0px !important;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px #eeeefd;
  h4 {
    background-color: #eebb6b;
    text-align: center;
    color: #ffffff;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    padding: 10px;
    margin-bottom: 0px;
    font-weight: bold;
  }
  .table {
    color: black;
    background-color: white;
    margin-bottom: 0px;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    thead {
      background-color: #a9b5c3;
    }
  }
  a {
    color: #ffffff;
    text-decoration: none !important;
    font-weight: bold;
    :hover,
    :focus {
      color: #ffffff !important;
    }
  }
}
#twitter-widget-1,
#twitter-widget-3 {
  height: 75vh !important;
}

#twitter-widget-1 .SandboxRoot.env-bp-820 .timeline-Tweet-text p {
  font-size: 16px !important;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 65% !important;
    margin: 1.75rem auto;
  }
}
@media (max-width: 576px) {
  .scroll-table {
    height: 350px !important;
  }
  .page-heading {
    width: 100%;
    margin: 0 !important;
    font-size: 12px !important;
  }
  .container-fluid {
    padding: 0px !important;
  }
  .navbar {
    height: 60px !important;
    padding: 0px 12px;
    .navbar-toggler {
      padding: 0.5rem;
      font-size: 16px;
      line-height: 1;
      border: none;
      border-radius: 0.25rem;
    }
    .btn {
      padding: 0;
    }
  }
  .sidebar {
    .nav-link {
      padding: 0;
    }
    .nav-item a {
      font-size: 12px;
    }
    .nav-item .btn {
      font-size: 12px;
    }
    .username {
      font-size: 18px !important;
    }
  }
  .brand-image {
    width: 110px !important;
    margin: 0px;
  }
  .scrollable-div {
    height: 600px !important;
    overflow: scroll;
  }
  .form-center {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 6px;
    box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.1);
    margin: 0 auto 30px;
    padding: 30px;
    // position: absolute;
    top: 5%;
    left: 0;
    text-align: left;
    vertical-align: -webkit-baseline-middle;
    width: 100% !important;
  }
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .navbar {
    height: 80px !important;
  }
  .nav-brand {
    font-size: 25px !important;
    width: 100px;
  }
  .brand-image {
    width: 110px;
    margin: 0px;
  }
  .scroll-table {
    overflow: scroll;
    height: 700px;
  }
  .scrollable-div {
    height: 800px;
    overflow: scroll;
  }
  .hide-overflow {
    overflow-x: hidden;
  }
  .leave-headings {
    font-size: 20px;
    text-align: left !important;
    padding-top: 5px;
  }
  .to-end-start {
    justify-content: flex-start !important;
    display: -webkit-box !important;
    padding-left: 20px;
  }
  #add-user-table {
    width: 600px !important;
  }
  #add-user-table tr td {
    width: 20%;
  }
  .page-heading {
    padding: 15px 0px;
    font-weight: bold;
    background: #5a5d66;
    color: #eebb6b;
    font-size: 25px !important;

    .col {
      text-align: center !important;
    }
    button {
      font-size: 18px;
    }
  }
  tbody {
    height: 200px;
    overflow: scroll;
    width: 100%;
  }
}

#input-leave {
  position: absolute;
  right: 67px;
  top: 283px;
}

#leave-checkbox {
  position: absolute;
  left: 93px;
  top: -13px;
}

#search-input-leave {
  position: absolute;
  width: 20%;
  top: 300px;
  right: 208px;
}

#search-leave-emp {
  width: 116px;
  position: absolute;
  top: 284px;
  left: 209px;
}

#emp-lbl {
  position: absolute;
  left: 65px;
  top: 295px;
}
#emp-startdate-lbl {
  position: absolute;
  left: 340px;
  top: 295px;
}
#btn-search {
  position: absolute;
  top: 284px;
  left: 667px;
}
#tt {
  position: absolute;
  top: 284px;
  left: 408px;
  width: 20%;
}
#btn-leave {
  position: absolute;
  top: 284px;
  left: 752px;
}
#sign-in {
  position: absolute;
  top: 35px;
  right: 53px;
}
#sign-out {
  position: absolute;
  top: 35px;
  right: 53px;
}
button:disabled {
  cursor: not-allowed !important;
}

.modal-sm {
  max-width: 40% !important;
}

.modal-lg {
  max-width: 100% !important;
}

.collapse.show {
  background-color: white !important;
  z-index: 1 !important;
  li {
    text-align: left;
    padding: 5px 15px;
    border-bottom: 1px solid black;
    :last-child {
      border: none;
    }
  }
}

.modal-header {
  background-color: #eebb6b;
  color: #ffffff;
  .modal-title {
    color: #ffffff;
  }
}

.MuiTableHead-root {
  background-color: #a9b5c3;
}

.menu-items {
  border: none !important;

  .nav-link {
    border: none !important;
    background-color: transparent !important;
  }
  .nav-items {
    border: none !important;
    background-color: transparent !important;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border: none !important;
    border-bottom: 3px solid orange !important;
    background-color: transparent !important;
  }

  .nav-item {
    border: none !important;
    .active {
      border: none !important;
      border-bottom: 3px solid orange !important;
      background-color: transparent !important;
    }
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-items:hover {
    border: none !important;
    border-bottom: 3px solid orange !important;
    background-color: transparent !important;
  }
}
.row-action {
  white-space: nowrap !important;
}
.MuiTablePagination-root {
  border-top: 1px solid black !important;
}
.makeStyles-highlight-6 {
  background-color: white !important;
}
th,
thead {
  color: black !important;
  font-weight: bold !important;
}
thead {
  background-color: #a9b5c3 !important;
}

.google-button {
  color: #ffffff !important;
  background-color: #eebb6b !important;
  font-size: 16px;
  border-radius: 10px !important;
  div {
    background-color: #eebb6b !important;
  }
  :hover,
  :focus {
    color: #000000;
  }
}
.MuiTableRow-root.Mui-selected {
  background-color: #ffffff !important;
}

.gten-dropdown-options {
  padding: 20px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 10px 10px 0 rgba(0, 0, 0, 0.19);
  border-radius: 3px;
  background: $white;
  position: absolute;
  z-index: 2;
  width: 100%;
  display: none;
  height: 200px;
  overflow-y: scroll;
}
.backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: none;
}
.gten-dropdown {
  position: relative;
  &.open {
    .gten-dropdown-options {
      display: block;
    }
    .backdrop {
      display: block;
    }
  }
}

section.pdf-toolbar {
  padding: 16px;
}

.MuiOutlinedInput-root {
  border-radius: 8px !important;
  border: 1px solid #edefef;
  fieldset {
    border: none !important;
  }
}
input:focus + fieldset {
  border: 1px solid #12a4ed !important;
}
select:focus {
  border: 1px solid #12a4ed !important;
}

.directory-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  border: 2px dashed rgb(204, 204, 204);
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
    cursor: pointer;
    background-color: transparent;
}

.directory-input-label {
  color: gray;
  cursor: pointer;
}

.directory-input {
  display: none; /* Hide the actual file input */
}

.file-list {
  margin-top: 10px;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
}

.file-list ul {
  list-style-type: none;
  padding: 0;
}

.file-list li {
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

