@import "./variables.scss";

.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}
a,
a:hover,
a:focus {
  text-decoration: none;
  transition: all 0.5s;
}
.pointer-cursor{
    cursor: pointer;
}
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  width: 22%;
    position: fixed;
    background-color: #fafafb;
    top: 0;
    left: -22%;
    height: 100vh;
    z-index: 1;
    color: #fff;
    transition: all 0.5s;
    overflow-y: auto;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  .profile{
    height: 70px;
    width: 70px;
    border: 1px solid red;
    border-radius: 50%;
  }
  a{
      color:#705058
  }
  .nav-item:first-child{
    padding: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 25%;
  }
  .nav-item{
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: inline-flex;
    margin-left: 0%;
    padding-left: 25%;
     a{
      color:#414f80;
      font-size: 15px;
    }
    a:hover{
      color: #eebb6b !important;
    }
  }
}


.sidebar.is-open {
  left: 0;
}

.sidebar-header {
  color: $black;
  font-size: $std-font-size - 2;
  font-weight: bold;
  background-color: #fafafb;
  .data-used{
      color: grey;
  }
}

.sidebar-header h3 {
  color: $white;
  padding: 1em;
}

.sidebar ul p {
  color: $white;
  padding: 10px;
}

.menu-open {
  background: #6d7fcc;
}

.nav-item:hover {
  color: #7386d5;
  background: $white;
}


.items-menu {
  color: $white;
  background: #6d7fcc;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header > a {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  color: $black;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  width: 100%;
  padding: 20px;
  min-height: 100vh;
  transition: all 0.5s;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fafafb;
}
.content.is-open {
  left: 22%;
  width: (100%-22%);
  overflow: hidden;
}
@media only screen and (max-width: 500px) {
  body {
    overflow: hidden;
  }
.sidebar{
  overflow-y: scroll;
  background-color: #fafafb;
}
  .content.is-open {
    margin-left: 300px;
    width: calc(100% - 300px);
  }
  .sidebar.is-open {
    overflow-y: scroll;
    min-width: 70%;
    max-width: 70%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }

  .sidebar.is-open > .sidebar-header a {
    display: unset;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}
#overlay {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(51, 45, 45, 0.72);
  z-index: 2;
  cursor: pointer;
  display: none;
}
.logout{
      color:#414f80 !important;
      font-weight: bold !important;
      
      &:hover{
        color: #7386d5 !important;
        text-decoration: none !important;
        
      }
}
